import { InsertChartOutlinedTwoTone, Label } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import GitHubIcon from '@mui/icons-material/GitHub'
import { Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation'
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { styled } from '@mui/material/styles';
// import MenuIcon from '@mui/icons-material/Menu';
export default function App() {
  const [prompt, setPrompt] = useState("")
  const [promptResult, setPromptResult] = useState("No results found yet")
  const [hidePromptResult, setHidePromptResult] = useState(true)
  const [hidePromptBox, setHidePromptBox] = useState(true)
  const [hideHome, setHideHome] = useState(false)
  

  const enableHome = async () => {
    setHidePromptBox(true)
    setHidePromptResult(true)
    setHideHome(false)
    setPrompt("")
    setPromptResult("")
  }
  const enablePrompt = async () => {
    setHidePromptBox(false)
    setHidePromptResult(true)
    setHideHome(true)
    setPrompt("")
    setPromptResult("")
  }
  const clear = async () => {
    setPrompt("")
    setPromptResult("")
    setHidePromptResult(true)
  }
  const getPromptResults = async () => {
    try {
      setHidePromptResult(true)
      if(prompt!=""){
        const urlWithPrompt = "https://mmmate-220530221614.us-east1.run.app/prompt/quiz/"+ prompt 
        const response = await fetch(urlWithPrompt)
        if (!response.ok) {
          throw Error(response.statusText)
        }
        const json = await response.json()
        setPromptResult(json.promptResult[0]+"\n\n\n\n")
        console.log(promptResult)
        setHidePromptResult(false);
      }
      else{
        setPromptResult("Invalid quiz topic");
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'white',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  }));
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              QUIZZY
            </Typography>
            <MenuIcon
              size="large"
              edge="end"
              color="inherit"
              onClick={() => enableHome()}
            >HOME
              {/* <GitHubIcon /> */}
            </MenuIcon>
          </Toolbar>
        </AppBar>
      </Box>

    {/* <Box sx={{ flexGrow: 0 }}> */}
      <Grid container spacing={3}>
        <Grid item xs={2}>
          {/* <Item>ADS go here</Item> */}
        </Grid>
        <Grid item xs={8}>
          <Box  padding={2} margin={5} hidden={hideHome}  component="section" 
          sx={{ p: 2, border: '4px', 
          backgroundColor:"white",
          borderRadius: '30px',border:"30px",overflowY: "scroll" ,
          'p':"30",
          'pt':"100px",
          'pl':"30px",
          'pr':"30px",
          'pb':"100px",
          flexGrow:"true"}}> 
            <Typography  variant="h3" 
            style={{'fontFamily': 'inherit',
            'p':"30px",
            'pl':"30px",
            'pr':"30px",
            textAlign:"center",}}>
            QUIZZY 
            </Typography>
            <Typography  variant="h5" 
            style={{'fontFamily': 'inherit',
            'p':"30px",
            'pl':"30px",
            'pr':"30px",
            textAlign:"center",}}>
             AI powered quiz generator 
            </Typography>
            <Typography  variant="h6" 
            style={{'fontFamily': 'inherit',
            'p':"30px",
            'pl':"30px",
            'pr':"30px",
            textAlign:"center",}} onClick={() => enablePrompt()} >

            <Button  onClick={() => enablePrompt()} align="center"
            style={{
            align:"center",
            textAlign:"center"}}>Click to try QUIZZY!</Button>
            </Typography>
         </Box>
          <Box  padding={2} margin={5} component="section"  hidden={hidePromptBox} 
          sx={{ p: 2, border: '4px', backgroundColor:"white",
          borderRadius: '30px',borderLeft:"30px"  }}> 
            <TextField id='prompt' fullWidth value={prompt} 
            onChange={(e) => setPrompt(e.target.value)} 
            variant="standard"/>
            <Button  onClick={() => getPromptResults()}>Submit</Button>
            <Button onClick={() => clear()}>Clear</Button>
            <Typography  variant="body2" 
            style={{'fontFamily': 'inherit',
            'p':"30px",
            'pl':"30px",
            'pr':"30px",
            textAlign:"center",}}>
            Enter topic to get upto 20 Quiz questions and answer keys.
            </Typography>
         </Box>
          <Box  padding={2} margin={5} hidden={hidePromptResult}  component="section" sx={{ p: 2, border: '4px', 
          backgroundColor:"white",
          borderRadius: '30px',border:"30px",overflowY: "scroll" ,
          'p':"30",
          'pl':"30px",
          'pr':"30px",
          flexGrow:"false"}}> 
            <Typography  variant="body2" 
            style={{'fontFamily': 'inherit',
            'p':"30px",
            'pl':"30px",
            'pr':"30px",
            textOverflow:"ellipsis" ,width:'inherit', 'display': 'block',
            wordWrap: "break-word",overflowY: "scroll",
            flexGrow:"false"}}>
              <pre   style={{'fontFamily': 'inherit',width:'.8', 
              'display': 'block',
              wordWrap: "break-word",
              textOverflow:"ellipsis",
              overflowY: "scroll" ,
              whiteSpace: 'pre-wrap',
              'p':"30px",
              'pl':"30px",
              'pr':"30px",
              flexGrow:"false"}}>
                {promptResult}
              </pre>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          {/* <Item>ADS go here</Item> */}
        </Grid>
      </Grid>
    {/* </Box> */}
     
      <BottomNavigation sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <CopyrightIcon/> <Typography  variant="body2" >Quizzy</Typography>
        </BottomNavigation>
    
    </>
  )
    }
